export const transactionFilterPayment = {
  key: 'paymentType',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gank Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
  filter: {
    key: 'paymentTypes',
    type: 'multiple',
    options: [
      { value: 'gold', text: 'Gank Gold' },
      { value: 'dragonpay_gcash', text: 'Gcash' },
      { value: 'grabpay', text: 'Grabpay' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'stripe_paynow', text: 'Paynow' },
      { value: 'stripe_promptpay', text: 'Promptpay' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'senangpay', text: 'SenangPay' },
      { value: 'xendit', text: 'Xendit' },
    ],
  },
}

export const transactionFilterPaymentV2 = {
  key: 'payment_src',
  label: 'Payment method',
  type: 'badge',
  map: {
    CARD: 'card',
    GRABPAY: 'grabpay',
    STRIPE_GRABPAY: 'grabpay',
    IPAYMU: 'ipaymu',
    GCASH: 'gcash',
    WECHATPAY: 'wechatpay',
    EARNINGS: 'success',
    WALLET_GANK_EARNING: 'success',
    STRIPE: 'stripe',
    PAYPAL: 'paypal',
    STRIPE_PAYNOW: 'stripe_paynow',
    SENANGPAY: 'senangpay',
    DRAGONPAY_GCASH: 'dragonpay_gcash',
    STRIPE_PROMPTPAY: 'stripe_promptpay',
    XENDIT: 'xendit',
    GOPAY: 'gopay',
    GOOGLEPLAY: 'googleplay',
    GOLD: 'gold',
    WALLET_GANK_GOLD: 'gold',
    OMISE_LINEYPAY: 'linepay',
    OMISE_TRUEMONEY: 'truemoney',
    MIDTRANS: 'midtrans',
    happypay: 'happypay',
    STRIPE_GOOGLE_PLAY: 'stripe_google_play',
    STRIPE_APPLEPAY: 'stripe_applepay',
  },
  filter: {
    key: 'payment_srcs',
    type: 'multiple',
    options: [
      { value: 'STRIPE_PAYNOW', text: 'Stripe paynow' },
      { value: 'DRAGONPAY_GCASH', text: 'Dragonpay gcash' },
      { value: 'IPAYMU', text: 'Ipaymu' },
      { value: 'STRIPE_GUEST', text: 'Stripe guest' },
      { value: 'STRIPE_PROMPTPAY', text: 'Stripe promptpay' },
      { value: 'XENDIT', text: 'Xendit' },
      { value: 'MIDTRANS', text: 'Midtrans' },
      { value: 'STRIPE', text: 'Stripe' },
      { value: 'INTERNAL', text: 'Internal' },
      { value: 'APPLE_STORE', text: 'Apple store' },
      { value: 'STRIPE_GRABPAY', text: 'Stripe grabpay' },
      { value: 'SENANGPAY', text: 'Senangpay' },
      { value: 'STRIPE_WECHAT_PAY', text: 'Stripe wechat pay' },
      { value: 'WALLET_GANK_EARNING', text: 'Wallet gank earning' },
      { value: 'GOOGLE_PLAY', text: 'Google play' },
      { value: 'PAYPAL', text: 'Paypal' },
      { value: 'WALLET_GANK_GOLD', text: 'Wallet gank gold' },
      { value: 'ZALOPAY', text: 'Zalopay' },
      { value: 'SIAMPAY', text: 'Siampay' },
      { value: 'HAPPYPAY', text: 'linepay' },
      { value: 'STRIPE_GOOGLE_PLAY', text: 'GooglePay' },
      { value: 'STRIPE_APPLEPAY', text: 'ApplePay' },
    ],
  },
}

export const transactionViewPayment = {
  key: 'paymentType',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
}

export const subscriptionFilterPayment = {
  key: 'paymentMethod',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gank Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
  filter: {
    key: 'paymentMethods',
    type: 'multiple',
    options: [
      { value: 'gold', text: 'Gank Gold' },
      { value: 'dragonpay_gcash', text: 'Gcash' },
      { value: 'grabpay', text: 'Grabpay' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'stripe_paynow', text: 'Paynow' },
      { value: 'stripe_promptpay', text: 'Promptpay' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'senangpay', text: 'SenangPay' },
      { value: 'xendit', text: 'Xendit' },
    ],
  },
}

export const subscriptionViewPayment = {
  key: 'paymentMethod',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
}

export const withdrawalFilterPayment = {
  key: 'payment',
  label: 'Payment method',
  type: 'payment',
  sortable: false,
  map: {
    affin_bank: 'Affin Bank',
    alliance_bank: 'Alliance Bank',
    aub: 'Asia United Bank',
    ambank: 'AmBank',
    bank_islam: 'Bank Islam',
    bank_rakyat: 'Bank Rakyat',
    bbl: 'Bangkok Bank',
    bca: 'BCA',
    bdo: 'BDO Pay',
    bdon: 'BDO Network Bank',
    bfb: 'BPI Family Savings',
    bni: 'BNI',
    boc: 'Bank of Commerce',
    bpi: 'BPI',
    bri: 'BRI',
    bsn: 'BSN',
    cbcs: 'Chinabank Savings',
    chinabank: 'China Bank',
    cimb_bank: 'Cimb Bank',
    citibank: 'Citibank',
    ctbc: 'Chinatrust Bank',
    dana: 'Dana',
    dbp: 'Development Bank of Philippines',
    dbs: 'DBS / POSB',
    ewb: 'Eastwest Bank',
    gcash: 'GCash',
    gopay: 'Gopay',
    hong_leong_bank: 'Hong Leong Bank',
    hsbc_bank: 'HSBC Bank',
    kbank: 'Kasikorn Thai',
    krungsri: 'Krungsri Bank',
    krungthai: 'Krungthai Bank',
    lbp: 'Landbank',
    mandiri: 'Mandiri',
    may: 'Maybank PH',
    maybank: 'Maybank MY',
    metro: 'Metro',
    ocbc_bank: 'OCBC Bank',
    omise_promptpay: 'Promptpay',
    ovo: 'OVO',
    paymaya: 'Paymaya',
    paynow: 'Paynow',
    paypal: 'Paypal',
    pbcm: 'PBCom',
    pnb: 'Philippine National Bank',
    psb: 'PSBank',
    public_bank: 'Public Bank',
    pvb: 'Philippines Veteran Bank',
    rcbc: 'RCBC',
    rhb_bank: 'RHB Bank',
    rsb: 'Robinsons Bank',
    sba: 'Sterling Bank of Asia',
    sbc: 'Security Bank',
    scb: 'Siam Commercial Bank',
    standard_chartered_bank: 'Standard Chartered Bank',
    stripe: 'Stripe',
    ucpb: 'UCPB',
    touch_n_go: 'Touch \'n Go',
    union: 'Union',
    uob: 'UOB',
    wise: 'Wise',
    zalopay: 'Zalopay',
    manual: 'Other Bank',
  },
  filter: {
    key: 'payments',
    type: 'multiple',
    options: [
      { value: 'affin_bank', text: 'Affin Bank' },
      { value: 'alliance_bank', text: 'Alliance Bank' },
      { value: 'ambank', text: 'AmBank' },
      { value: 'aub', text: 'Asia United Bank' },
      { value: 'bank_islam', text: 'Bank Islam' },
      { value: 'bank_rakyat', text: 'Bank Rakyat' },
      { value: 'bbl', text: 'Bangkok Bank' },
      { value: 'bca', text: 'BCA' },
      { value: 'bdon', text: 'BDO Network Bank' },
      { value: 'bdo', text: 'BDO Pay' },
      { value: 'bfb', text: 'BPI Family Savings' },
      { value: 'bni', text: 'BNI' },
      { value: 'boc', text: 'Bank of Commerce' },
      { value: 'bpi', text: 'BPI' },
      { value: 'bri', text: 'BRI' },
      { value: 'bsn', text: 'BSN' },
      { value: 'cbcs', text: 'Chinabank Savings' },
      { value: 'chinabank', text: 'China Bank' },
      { value: 'cimb_bank', text: 'Cimb Bank' },
      { value: 'citibank', text: 'Citibank' },
      { value: 'ctbc', text: 'Chinatrust Bank' },
      { value: 'dana', text: 'Dana' },
      { value: 'dbp', text: 'Development Bank of Philippines' },
      { value: 'dbs', text: 'DBS / POSB' },
      { value: 'ewb', text: 'Eastwest Bank' },
      { value: 'gcash', text: 'GCash' },
      { value: 'gopay', text: 'Gopay' },
      { value: 'hong_leong_bank', text: 'Hong Leong Bank' },
      { value: 'hsbc_bank', text: 'HSBC Bank' },
      { value: 'kbank', text: 'Kasikorn Thai' },
      { value: 'krungsri', text: 'Krungsri Bank' },
      { value: 'krungthai', text: 'Krungthai Bank' },
      { value: 'lbp', text: 'Landbank' },
      { value: 'mandiri', text: 'Mandiri' },
      { value: 'manual', text: 'Other Bank' },
      { value: 'may', text: 'Maybank PH' },
      { value: 'maybank', text: 'Maybank MY' },
      { value: 'metro', text: 'Metro' },
      { value: 'ocbc_bank', text: 'OCBC Bank' },
      { value: 'omise_promptpay', text: 'Promptpay' },
      { value: 'ovo', text: 'OVO' },
      { value: 'paymaya', text: 'Paymaya' },
      { value: 'paynow', text: 'Paynow' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'pbcm', text: 'PBCom' },
      { value: 'pnb', text: 'Philippine National Bank' },
      { value: 'psb', text: 'PSBank' },
      { value: 'public_bank', text: 'Public Bank' },
      { value: 'pvb', text: 'Philippines Veteran Bank' },
      { value: 'rcbc', text: 'RCBC' },
      { value: 'rhb_bank', text: 'RHB Bank' },
      { value: 'rsb', text: 'Robinsons Bank' },
      { value: 'sba', text: 'Sterling Bank of Asia' },
      { value: 'sbc', text: 'Security Bank' },
      { value: 'scb', text: 'Siam Commercial Bank' },
      { value: 'standard_chartered_bank', text: 'Standard Chartered Bank' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'touch_n_go', text: 'Touch \'n Go' },
      { value: 'ucpb', text: 'UCPB' },
      { value: 'union', text: 'Union' },
      { value: 'uob', text: 'UOB' },
      { value: 'wise', text: 'Wise' },
      { value: 'zalopay', text: 'Zalopay' },
    ],
  },
}

export const withdrawalViewPayment = {
  key: 'payment',
  label: 'Payment method',
  type: 'payment',
  map: {
    affin_bank: 'Affin Bank',
    alliance_bank: 'Alliance Bank',
    aub: 'Asia United Bank',
    ambank: 'AmBank',
    bank_islam: 'Bank Islam',
    bank_rakyat: 'Bank Rakyat',
    bbl: 'Bangkok Bank',
    bca: 'BCA',
    bdo: 'BDO Pay',
    bdon: 'BDO Network Bank',
    bfb: 'BPI Family Savings',
    bni: 'BNI',
    boc: 'Bank of Commerce',
    bpi: 'BPI',
    bri: 'BRI',
    bsn: 'BSN',
    cbcs: 'Chinabank Savings',
    chinabank: 'China Bank',
    cimb_bank: 'Cimb Bank',
    citibank: 'Citibank',
    ctbc: 'Chinatrust Bank',
    dana: 'Dana',
    dbp: 'Development Bank of Philippines',
    dbs: 'DBS / POSB',
    ewb: 'Eastwest Bank',
    gcash: 'GCash',
    gopay: 'Gopay',
    hong_leong_bank: 'Hong Leong Bank',
    hsbc_bank: 'HSBC Bank',
    kbank: 'Kasikorn Thai',
    krungsri: 'Krungsri Bank',
    krungthai: 'Krungthai Bank',
    lbp: 'Landbank',
    mandiri: 'Mandiri',
    may: 'Maybank PH',
    maybank: 'Maybank MY',
    metro: 'Metro',
    ocbc_bank: 'OCBC Bank',
    omise_promptpay: 'Promptpay',
    ovo: 'OVO',
    paymaya: 'Paymaya',
    paynow: 'Paynow',
    paypal: 'Paypal',
    pbcm: 'PBCom',
    pnb: 'Philippine National Bank',
    psb: 'PSBank',
    public_bank: 'Public Bank',
    pvb: 'Philippines Veteran Bank',
    rcbc: 'RCBC',
    rhb_bank: 'RHB Bank',
    rsb: 'Robinsons Bank',
    sba: 'Sterling Bank of Asia',
    sbc: 'Security Bank',
    scb: 'Siam Commercial Bank',
    standard_chartered_bank: 'Standard Chartered Bank',
    ucpb: 'UCPB',
    touch_n_go: 'Touch \'n Go',
    union: 'Union',
    uob: 'UOB',
    zalopay: 'Zalopay',
    manual: 'Other Bank',
  },
}
